<template>
  <div class="page">  
    <div  class="lora-training">
      <div v-if="showBanner" class="create-agent-banner">
          Close Button
          <!-- <button class="close-banner-btn" @click="closeBanner">&times;</button> -->

          <h3>Build Your Virtual Influencer</h3>
        
          <p>Promote your social media by building a virtual influencer using AI</p>
        </div>
    <!-- Loader -->
    <div class="main-loader" v-if="isLoading"></div>
    

      <!-- Training Progress & Completion Popup -->
      <div v-if="trainingCompleted" class="training-completed-popup">
        <h2>Congrats {{ this.userName }}! Agent training completed successfully!</h2>
        <p>Now click below to start generating images using your brand-new creative agent!</p>
        <button @click="goToAgentPage">Go to Agent</button>
      </div>
      <div v-if="progressVisible && !trainingCompleted" class="progress-tracker">
        <h3>Training Progress:</h3>
        <p>Please keep this page open until the training process is complete.</p>
        <p>{{ progressMessage }}</p>
        <progress :value="progressValue" :max="progressMax" class="progress-bar"></progress>
      </div>
      <div class="banner-subheading">
      <!-- <h5> Create your creative assistant by uploading images to train it. Once the training process is complete, you'll be ready to generate new endless images using AI.<br><br></h5> -->
      </div>
      <h5>Instructions:</h5>
        <ol>
    <li>Upload at least 6 high-quality photos (10-15 recommended). Photos taken with your phone are recommended for high-quality images. </li>
    <li> Include close-up shots, full body, and different angles</li>
    <li>Quick photo tips:
        - Only your face and body in the images
        - Good lighting
        - Clear, not blurry </li>
    <li>We'll email you when your agent is ready to generate images!</li>
</ol>

      <h5> Influencer Details:</h5>
      <div class="model-details">
        <div class="form-row">
          <label for="loraName">Influencer Name*:</label>
          <input type="text" v-model="loraName" id="loraName" class="input-field" placeholder="Enter your new influencer name" />
        </div>

        <!-- <div class="form-row"> -->
  <!-- <label for="agentType">Agent Type*:</label> -->
  <!-- <select v-model="agentType" id="agentType" class="input-field">
    <option disabled value="">Please select</option>
    <option value="product">Product Marketing</option>
    <option value="person">Influencer</option>
  </select> -->
<!-- </div> -->

<!-- Additional fields for Product Marketing -->
<!-- <div v-if="agentType === 'product'">
  <div class="form-row">
    <label for="productType">Select Product Type*:</label>
    <select v-model="productType" id="productType" class="input-field">
      <option disabled value="">Please select</option>
      <option value="fashion">Fashion & Accessories</option>
      <option value="beauty">Beauty & Personal Care</option>
      <option value="food">Food & Beverages</option>
      <option value="health">Health & Wellness</option>
      <option value="furniture">Furniture & Home Decor</option>
      <option value="technology">Technology & Gadgets</option>
      <option value="toys">Toys & Baby Products</option>
      <option value="automotive">Automotive & Accessories</option>
      <option value="books">Books & Stationery</option>
      <option value="sports">Sports & Outdoors</option>
      <option value="pets">Pets & Pet Care</option>
      <option value="appliances">Home Appliances</option>
      <option value="seasonal">Seasonal & Festival Items</option>
      <option value="product">Other</option>
    </select>
  </div>
  <div class="form-row">
    <label for="productName">Product Name*:</label>
    <input type="text" v-model="productName" id="productName" class="input-field" placeholder="Enter a unique product name, ex: blue bottle with black cap" />
  </div>
</div> -->

<!-- Additional fields for Influencer -->
<div v-if="agentType === 'person'">
  <div class="form-row">
    <label for="influencerGender">Gender*:</label>
    <select v-model="influencerGender" id="influencerGender" class="input-field">
      <option disabled value="">Please select</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <!-- Consider adding non-binary or other options if applicable -->
    </select>
  </div>
  <!-- <div class="form-row">
    <label for="influencerName">Influencer Name*:</label>
    <input type="text" v-model="influencerName" id="influencerName" class="input-field" placeholder="Enter a unique influencer name " />
  </div> -->
</div>

<!-- Common details -->
<div class="form-row">
  <label for="purpose">Influencer Type:</label>
  <input type="text" v-model="purpose" id="purpose" class="input-field" :placeholder="purposePlaceholder" />
</div>

        <!-- <div class="form-row">
          <label for="conceptSentence">Trigger word/sentence*:</label>
          <input type="text" v-model="conceptSentence" id="conceptSentence" class="input-field" />
        </div> -->
        <!-- <p class="note">Note: This trigger word/sentence needs to be as unique as possible to trigger your images while generating new images, example: mybluebottleXYZ, myCarHonda2022 etc.</p> -->
      </div>

      <h5>Upload Images*</h5>
      <div class="image-input-options">
        <div class="upload-option">
          <input type="file" multiple @change="handleImageUpload" accept="image/jpeg, image/webp, image/png, image/heic, image/heif" class="upload-input" />
          <p class="note">Upload images to start training process.</p>
        </div>
        <!-- <div class="url-option">
          <input
            type="text"
            v-model="newImageUrl"
            placeholder="Add Image URL"
            class="url-field"
            @keyup.enter="addImageUrl"
          />
          <button @click="addImageUrl" class="add-btn">Add Image URL</button>
          <p class="note">Or add image URLs by pasting them here.</p>
        </div> -->
      </div>

      <h4 v-if="allImages.length > 0">Uploaded images:</h4>
      <div class="image-captions-list" v-if="allImages.length > 0">
        <div v-for="(imageItem, index) in allImages" :key="imageItem.id" class="image-caption-item">
          <img :src="imageItem.dataUrl" :alt="'Image ' + (index + 1)" class="image-preview" />
          <div class="caption-delete">
             <input
              type="text"
              v-model="imageItem.caption"
              :placeholder="'Caption for Image ' + (index + 1)"
              class="caption-field"
            /> 
            <button @click="deleteImage(index)" class="delete-btn">X</button>
          </div>
        </div>
      </div>
      <button
        @click="handleButtonClick"
        :disabled="isTraining"
        :class="['start-btn', { 'in-progress': isTraining, 'completed': trainingCompleted }]">
        <feather-icon size="16" icon="ZapIcon" class="mr-25" /> 
        {{ trainingButtonText }}
      </button>
    </div>
    <div v-if="!isMobileDevice" class="floating-qr">
        <div class="qr-card">
          <div class="qr-header">
            <h4>Upload from Your Phone</h4>
            <button class="close-btn" @click="hideQR" v-if="!isQRHidden">×</button>
          </div>
          <div class="qr-content" v-if="!isQRHidden">
            <p>Take better photos directly from your mobile device!</p>
            <div class="qr-placeholder">
              <img :src="qrCodeUrl" alt="QR Code" class="qr-image" />
            </div>
            <div class="qr-instructions">
              <ol>
                <li>Scan this QR code with your phone</li>
                <li>Take high-quality photos directly with your camera</li>
                <li>Upload them instantly to start training your influencer agent</li>
              </ol>
            </div>
          </div>
          <!-- Show minimized view when hidden -->
          <div class="qr-minimized" v-if="isQRHidden" @click="showQR">
            <span>📱 Open Mobile Upload</span>
          </div>
        </div>
      </div>    
  </div>

</template>

<script>
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import loadImage from 'blueimp-load-image';
import heic2any from 'heic2any';
import QRCode from 'qrcode';
export default {
  name: 'LoraTraining',
  data() {
    return {
      qrCodeUrl: '',
      isMobileDevice: false,
      isLoading: false,
      isTraining: false,
      trainingCompleted: false,
      showBanner: true,
      allImages: [],
      newImageUrl: '', // New image URL input
      captions: [], // Array to hold captions
      loraName: '', // Model/Agent name
      description: '', // Agent Description
      triggerWord: '', // Unique trigger word
      agentType: 'person', // Agent Type
      productType: '', // For storing product type
      productName: '', // For storing product name
      influencerGender: '', // For storing influencer gender
      influencerName: '', // For storing influencer name
      purpose: '', // Purpose based on Agent Type
      datasetId: '', // Dataset ID returned from API
      trainingId: '', // Training ID returned from API
      agentId: '',
      progressVisible: false, // Flag to show/hide progress tracker
      progressMessage: '', // Message to display training progress
      progressValue: 0, // Current progress value
      progressMax: 100, // Max progress value
      sseSource: null, // EventSource for SSE
      userId: '',
      userName: '', // Add userName with default empty string
      email: '', 
      isQRHidden: false
    };
  },
  computed: {
    trainingButtonText() {
      if (this.trainingCompleted) return 'Completed';
      if (this.isTraining) return 'In Progress';
      return 'START TRAINING';
    },
    loraNameSanitized() {
    const sanitizedName = this.loraName.replace(/\s+/g, '').toLowerCase();
    const timestamp = new Date().toISOString(); // Generates current date/time in ISO format
    return `${sanitizedName}-${timestamp}`;
    },
    purposePlaceholder() {
      if (this.agentType === 'product') {
        return "Whats your product and your assistant to help you create";
      } else if (this.agentType === 'person') {
        return 'What type of influencer?';
      }
      return '';
    }
  },
  async created() {
  // Get training ID from multiple sources in order of priority
  this.trainingId = 
    this.$route.params.trainingId || 
    this.$route.query.trainingId || 
    localStorage.getItem('trainingId');

  if (this.trainingId) {
    this.isTraining = true;
    this.trackTrainingProgress();
  }

  // Device detection using a more reliable method
  this.isMobileDevice = window.matchMedia('(max-width: 768px)').matches || 
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
},
  watch: {
     purpose(newVal) {
       if (newVal) {
         this.generateTriggerWord();
       }
     },
    trainingCompleted(newVal) {
      if (newVal) {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }
    },
    isTraining(newVal) {
      if (newVal) {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }
    },
  productName(newVal) {
    if (this.agentType === 'person') {
      this.generateTriggerWord();
    }
  },
  
  userName(newVal) {
    if (newVal)
      this.generateTriggerWord();
    }
  },


async mounted() {
  // Move QR code generation to mounted hook since it's DOM-related
  if (!this.isMobileDevice) {
    try {
      this.qrCodeUrl = await QRCode.toDataURL(window.location.href, {
        width: 200,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#ffffff'
        }
      });
    } catch (err) {
      console.error('Error generating QR code:', err);
    }
  }
},

  methods: {
    hideQR() {
      this.isQRHidden = true;
    },
    showQR() {
      this.isQRHidden = false;
    },
    async handleButtonClick() {
      // Check if user is logged in
      const user = JSON.parse(localStorage.getItem('userData'));
      this.userId = user?.userId;
      this.userName = user?.userName;
      this.email = user?.email; 

      if (!this.userId) {
        const loginSuccess = await this.showLoginModal();
        if (!loginSuccess) {
          return;
        } else {
          // After successful login, get the userId again
          const user = JSON.parse(localStorage.getItem('userData'));
          this.userId = user?.userId;
          this.userName = user?.userName;
          this.email = user?.email; 
          if (!this.userId) {
            this.showToast('An error occurred while logging in.', 'error');
            return;
          }
              // Generate the trigger word after successfully setting the user data
        this.generateTriggerWord();
        }
      }

      if (this.trainingCompleted) {
        // Redirect to the create images page
        window.location.href = `/agent/${this.agentId}`;
      } else {
        // Start training
        this.startTraining();
      }
    },

    generateTriggerWord() {
  const user = JSON.parse(localStorage.getItem('userData'));
  this.userName = user?.userName || '';

  // Function to sanitize and shorten names
  const sanitizeAndShorten = (str) => {
    return str
      ? str.toLowerCase().replace(/[^a-z0-9]/g, '').slice(0, 3) // Keep first 3 letters
      : 'xxx'; // Default filler
  };

  // Process user name
  const cleanUserName = sanitizeAndShorten(this.userName) || 'usr';
  const userPart = cleanUserName.endsWith('s') ? cleanUserName : `${cleanUserName}s`;

  // Determine content-based identifier
  let contentPart = 'xxx';
  if (this.agentType === 'product') {
    contentPart = sanitizeAndShorten(this.productName || 'prd');
  } else if (this.agentType === 'person') {
    contentPart = sanitizeAndShorten(this.loraName || 'inf');
  }

  // Generate random number for uniqueness
  const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit number

  // Construct final trigger word
  this.triggerWord = `${userPart}-${contentPart}-${randomNum}`;
},

    /**
     * Handles image uploads from the file input.
     * Converts HEIC images to JPEG and corrects EXIF orientation.
     */
    async handleImageUpload(event) {
      this.isLoading = true; // Start loading
      const files = event.target.files;

      for (let file of files) {
        // Limit to 25 images
        if (this.allImages.length >= 25) {
          alert('You can only upload up to 25 images.');
          break;
        }

        let processedFile = file;

        // Step 1: Convert HEIC/HEIF to JPEG for preview if necessary
    if (
      file.type === 'image/heic' ||
      file.type === 'image/heif' ||
      file.name.endsWith('.heic') ||
      file.name.endsWith('.HEIC') ||
      file.name.endsWith('.heif') ||
      file.name.endsWith('.HEIF')
    ) {
      try {
        // Convert HEIC/HEIF to JPEG with a quality of 0.8
        const conversionResult = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.8,
        });

        // heic2any returns an array of blobs
        if (Array.isArray(conversionResult)) {
          processedFile = conversionResult[0];
        } else {
          processedFile = conversionResult;
        }
        // Assign original file name with .jpg extension
        processedFile.name = file.name.replace(/\.[^/.]+$/, '') + '.jpg';
        processedFile.lastModified = file.lastModified;
      } catch (error) {
        console.error('HEIC conversion failed:', error);
        alert(`Failed to convert ${file.name}. Please try another image.`);
        continue; // Skip this file and proceed with others
      }
    }

        // Step 2: Read the (converted) image file as Data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          // Step 3: Handle EXIF orientation using blueimp-load-image
          loadImage(
            e.target.result,
            (canvas) => {
              if (canvas.type === 'error') {
                console.error('Error loading image');
                alert(`Failed to process ${file.name}.`);
                return;
              }

              // Convert the canvas to a Data URL (JPEG)
              const dataUrl = canvas.toDataURL(processedFile.type);

              // Step 4: Create an image item and add it to the allImages array
              const imageItem = {
                id: Date.now() + Math.random(),
                dataUrl: dataUrl,
                caption: '',
                file: processedFile,
                previewFile: processedFile, // **Include the file object**
              };
              this.allImages.push(imageItem);
            },
            {
              orientation: true, // Corrects the orientation based on EXIF data
              canvas: true,      // Outputs the image as a canvas
              maxWidth: 1000,    // Optional: Resize the image if it's too large
              maxHeight: 1000,
            }
          );
        };
        reader.onerror = (error) => {
          console.error('File reading failed:', error);
          alert(`Failed to read ${file.name}. Please try again.`);
        };
        reader.readAsDataURL(processedFile);
      }

      // Reset file input
      event.target.value = '';
      this.isLoading = false; // End loading
    },
    // Add image URL
    addImageUrl() {
      const urlPattern = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|svg|webp))(?:\?.*)?$/i;
      if (this.newImageUrl.trim() !== '') {
        if (urlPattern.test(this.newImageUrl.trim())) {
          const imageItem = {
            id: Date.now() + Math.random(),
            dataUrl: this.newImageUrl.trim(),
            caption: '',
          };
          // Limit to 25 images
          if (this.allImages.length >= 25) {
            alert('You can only upload up to 25 images.');
            return;
          }
          this.allImages.push(imageItem);
          this.newImageUrl = '';
        } else {
          alert('Please enter a valid image URL (e.g., https://example.com/image.jpg)');
        }
      }
    },
    deleteImage(index) {
      this.allImages.splice(index, 1);
    },
    // Start the training process
    async startTraining() {
      if (!this.validateInputs()) {
        return;
      }
      try {
        this.isLoading = true; // Start loader
        this.isTraining = true; // Disable start button and indicate training is in progress
        this.trainingCompleted = false; // Reset training completed status

        // Step 1: Dataset creation
        const datasetResponse = await this.createDataset();
        this.datasetId = datasetResponse.data.datasetId;
        this.showToast('Images uploaded and now starting the training process.');

        // Step 2: Start training
        const trainingResponse = await this.startTrainingProcess();
        this.trainingId = trainingResponse.data.trainingId;
        // Store trainingId in localStorage
        localStorage.setItem('trainingId', this.trainingId);
        this.showToast('Training process started! Please be patient as it will take some time to complete.');

        // Track training progress
        this.trackTrainingProgress();
      } catch (error) {
        console.error('Error during training process:', error);
        this.showToast('An error occurred during training. Please try again.', 'error');
        this.isLoading = false; // Stop loader on error
        this.isTraining = false; // Re-enable start button
      }
    },
    // Validate inputs before starting the training
    validateInputs() {
      if (this.allImages.length < 6) {
        this.showToast('Please upload at least 6 images.', 'error');
        return false;
      }
      if (!this.loraName) {
        this.showToast('Please enter your Agent name.', 'error');
        return false;
      }
      if (!this.agentType) {
        this.showToast('Please select your Agent Type.', 'error');
        return false;
      }
      // if (!this.purpose) {
      //   this.showToast('Please tell about product or influencer type for better results.', 'error');
      //   return false;
      // }
      return true;
    },
    // Creates a dataset by sending images and URLs to the server.
    async createDataset() {
      const formData = new FormData();

      // Separate images and imageURLs
      const imageURLs = [];
      this.allImages.forEach((imageItem) => {
        if (imageItem.file) {
          formData.append('images', imageItem.file);
        } else if (imageItem.dataUrl.startsWith('http')) { // Ensure it's a valid URL
          imageURLs.push(imageItem.dataUrl);
        }
      });
      if (imageURLs.length > 0) {
        formData.append('imageURLs', JSON.stringify(imageURLs));
      }

      // Process captions
       const processedCaptions = this.allImages.map((imageItem) =>
         imageItem.caption
           ? `${this.triggerWord} ${imageItem.caption} `
           : this.triggerWord
       );
      formData.append('captions', JSON.stringify(processedCaptions));
      formData.append('conceptSentence', this.triggerWord);

      // Append conceptSentence (triggerWord) to the dataset
      if (this.agentType) {
    formData.append('agentType', this.agentType);
  }
  if (this.productType) {
    formData.append('productType', this.productType);
  }
  if (this.influencerGender) {
    formData.append('influencerGender', this.influencerGender);
  }

      // Configure axios to send FormData
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      try {
        // Call the API
        const response = await axios.post('https://train.briks.ai/create-dataset', formData, config);
        // Handle success (e.g., notify the user or redirect)
        // alert('Dataset created successfully!');
        return response;
      } catch (error) {
        console.error('API call failed:', error.response ? error.response.data : error.message);
        alert('Failed to create dataset. Please check your inputs and try again.');
        throw error; // Add this line to let the caller know that an error occurred
      }
    },
    // Start training process by calling the API
    async startTrainingProcess() {

      const payload = {
        agentName: this.loraName,
        loraName: this.loraNameSanitized,
        conceptSentence: this.triggerWord,
        steps: 1500,
        dataset: this.datasetId,
        userId: this.userId,
        description: this.description,
        agentType: this.agentType,
        influencerName: this.loraName,
        ...(this.purpose && { purpose: this.purpose }),
    ...(this.productType && { productType: this.productType }),
    ...(this.productName && { productName: this.productName }),
    ...(this.influencerGender && { influencerGender: this.influencerGender }),
    // ...(this.influencerName && { influencerName: this.loraName }),
        userName: this.userName,
        email: this.email,
      };

      try {
        const response = await axios.post('https://train.briks.ai/start-training', payload);
        return response;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error === 'Trigger word already exists') {
          // Generate a new trigger word
          this.generateTriggerWord();
          // Inform the user
          this.showToast('Trigger word conflict detected. Generated a new trigger word.', 'warning');
          // Retry training with new trigger word
          return await this.startTrainingProcess();
        } else {
          // Handle other errors
          console.error('Error starting training:', error);
          this.showToast('An error occurred while starting the training.', 'error');
          throw error;
        }
      }
    },
    // Track training progress via SSE
  trackTrainingProgress() {
  this.progressVisible = true;
  this.progressMessage = 'Training started...';
  this.progressValue = 0;
  this.progressMax = 100;

  if (this.sseSource) {
    this.sseSource.close();
  }

  this.sseSource = new EventSource(`https://train.briks.ai/progress/${this.trainingId}`);

  this.sseSource.onmessage = async (event) => {
    try {
      const data = JSON.parse(event.data);
      console.log('Received SSE data:', data);

      if (data.type === 'progress') {
        const { percentComplete } = data;
        this.progressValue = percentComplete;
        this.progressMax = 100;
        this.progressMessage = `Training Progress: ${percentComplete}%`;
      } else if (data.type === 'postTrainingComplete' || data.type === 'end') {
        // Training and post-training steps completed
        this.handleTrainingCompletion();
      } else if (data.type === 'status') {
        // Handle status updates from Redis
        if (data.status === 'completed') {
          this.handleTrainingCompletion();
        } else if (data.status.startsWith('failed')) {
          this.handleTrainingFailure();
        } else {
          // Handle other statuses if any
          this.progressMessage = 'Training status: ' + data.status;
        }
      } else if (data.type === 'error') {
        // Handle error messages from the server
        console.error('Training error:', data.message);
        this.showToast(`Error: ${data.message}`, 'error');
        if (this.sseSource) {
          this.sseSource.close();
          this.sseSource = null;
        }
        this.isLoading = false; // Stop loader
        this.isTraining = false; // Re-enable start button

        // Remove stored trainingId
        localStorage.removeItem('trainingId');

        // Hide progress bar
        this.progressVisible = false;
      } else {
        console.log('Received unknown data type:', data);
      }
    } catch (error) {
      console.error('Error processing SSE message:', error);
      // this.showToast('An error occurred while processing training progress.', 'error');
      this.isLoading = false; // Stop loader
      this.isTraining = false; // Re-enable start button

      // Remove stored trainingId
      localStorage.removeItem('trainingId');

      // Hide progress bar
      this.progressVisible = false;
    }
  };

  this.sseSource.onerror = (error) => {
    console.error('Error in SSE:', error);

    if (!this.trainingCompleted) {
      setTimeout(() => {
        console.log('Attempting to reconnect SSE...');
        this.trackTrainingProgress();
      }, 25000); // Retry every 5 seconds

      if (this.sseSource) {
        this.sseSource.close();
        this.sseSource = null;
      }
      // this.showToast('An error occurred while tracking training progress.', 'error');
      this.isLoading = false; // Stop loader
      this.isTraining = false; // Re-enable start button
    } else {
      console.log('SSE connection closed after training completed.');
      if (this.sseSource) {
        this.sseSource.close();
        this.sseSource = null;
      }
    }
  };
},
handleTrainingFailure() {
    this.progressMessage = 'Training failed.';
    this.trainingCompleted = false; // Ensure it's false
    this.isTraining = false;

    // Remove stored trainingId
    localStorage.removeItem('trainingId');

    if (this.sseSource) {
      this.sseSource.close();
      this.sseSource = null;
    }
    this.isLoading = false;

    // Hide progress bar
    this.progressVisible = false;
    this.showToast('Training failed. Please try again.', 'error');
  },

handleTrainingCompletion() {
    this.progressMessage = 'Training completed!';
    this.trainingCompleted = true; // Indicate training is completed
    this.isTraining = false; // Re-enable start button
    if (this.sseSource) {
      this.sseSource.close();
      this.sseSource = null;
    }
    this.isLoading = false; // Stop loader

    // Remove stored trainingId
    localStorage.removeItem('trainingId');

    // Hide progress bar
    this.progressVisible = false;
    this.showToast('Training process completed and your agent is ready to use.');

    // Redirect to Agent Page
    this.agentId = `agent-${this.trainingId}`;
    // this.goToAgentPage();
  },

    // Redirect to Agent Page
    goToAgentPage() {
      window.location.href = `/agent/${this.agentId}`;
    },
    // Show login modal
    showLoginModal() {
      return new Promise((resolve) => {
        this.$bvModal.show('modal-login');
        const handleLoginSuccess = () => {
          resolve(true);
          cleanup();
        };
        const handleLoginFailure = () => {
          resolve(false);
          cleanup();
        };
        const cleanup = () => {
          this.$root.$off('login-success', handleLoginSuccess);
          this.$root.$off('login-failure', handleLoginFailure);
        };
        this.$root.$on('login-success', handleLoginSuccess);
        this.$root.$on('login-failure', handleLoginFailure);
      });
    },
    showToast(message, type = 'success') {
      // Use the ToastificationContent component for notifications
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon:
            type === 'success'
              ? 'CheckIcon'
              : type === 'error'
              ? 'AlertTriangleIcon'
              : 'InfoIcon',
          variant: type,
        },
      });
    },
  },
};
</script>

<style scoped>
.image-input-options {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}
/* Styles for displaying images with captions */
.image-captions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Creates responsive columns */
  gap: 15px; /* Space between items */
}

.image-caption-item {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center items */
}

.image-preview {
  width: 100%; /* Use full width of the container */
  height: auto; /* Maintain aspect ratio */
  margin: 10px; /* Space between image and caption */
}


.caption-delete {
  flex: 1;
  display: flex;
  align-items: center;
}

.caption-field {
  flex: 1;
  padding: 5px;
  margin-right: 10px;
}

.delete-btn {
  background-color: #ffffff;
  color: #ff0000;
  border: none;
  padding: 5px 5px;
  font-size: 15px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #fff;
}
.upload-option,
.url-option {
  flex: 1;
  min-width: 250px;
  margin-right: 20px;
}
/* Form Row Styling */
.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.form-row label {
  width: 200px;
  min-width: 150px;
  font-size: 14px !important;
}

.form-row .input-field {
  flex: 1;
  min-width: 200px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-row label {
    width: 100%;
  }

  .form-row .input-field {
    width: 100%;
  }
  .caption-delete {
    flex-direction: column;
    width: 100%;
  }
  .caption-field,
  .delete-btn {
    width: 100%;
    margin-bottom: 5px;
  }
  .delete-btn {
  background-color: #fff;
  color: red;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: 20%;
}

}

/* Training Completion Popup */
.training-completed-popup {
  border: 2px solid #4caf50;
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
  background-color: #e8f5e9;
  text-align: center;
}

.training-completed-popup h2 {
  color: #2e7d32;
}

.training-completed-popup button {
  padding: 10px 20px;
  background-color: #2e7d32;
  color: #fff;
  border: none;
  cursor: pointer;
}

.training-completed-popup button:hover {
  background-color: #1b5e20;
}

/* Form Row Styling */
.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
  padding: 10px;
}

.form-row label {
  width: 200px;
  min-width: 150px;
}

.form-row .input-field {
  flex: 1;
  max-width: 50%;
  max-height: 50%;

}

/* Responsive Design */
@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    align-items: flex-start;
  }


  .form-row label {
    width: 100%;
  }

  .form-row .input-field {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .url-field {
    /* flex-grow: 1; */
    padding: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 15px;
    width: 70%;
  }
}
/* Training Completion Popup */
.training-completed-popup {
  border: 2px solid #4caf50;
  padding: 20px;
  margin-top: 20px;
  background-color: #e8f5e9;
  text-align: center;
}

.training-completed-popup h2 {
  color: #2e7d32;
}

.training-completed-popup button {
  padding: 10px 20px;
  background-color: #2e7d32;
  color: #fff;
  border: none;
  cursor: pointer;
}

.training-completed-popup button:hover {
  background-color: #1b5e20;
}

.title h4 {
  text-align: center;
  font-weight: semi-bold !important; /* Ensure bold styling is applied */
  font-size: 1.5rem !important; /* Use !important to override any conflicting styles */
  padding: 10px;

}
  .lora-training {
    padding: 40px;
    padding-left: 60px;
    padding-top: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    /* font-family: Arial, sans-serif; */
  }
  .page {
    height:100%;
    /* padding: 40px; */
    padding-top: 10px;
    padding-bottom: 40px;
    background-color: #ffffff;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    /* font-family: Arial, sans-serif; */
  }
  
  h2, h4, h3 {
    color: #000000;
  }
  
  ol {
    padding-left: 20px;
  }
  
  .upload-input {
    margin-bottom: 5px;
    padding: 5px;
  }
  
  .image-previews {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .image-preview {
    margin: 5px;
    /* border: 1px solid #ddd; */
    padding: 5px;
    border-radius: 5px;
  }
  
  .image-preview img {
    max-width: 150px;
    max-height: 150px;
  }
  
  .url-input {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .url-field {
    /* flex-grow: 1; */
    padding:9px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    margin-right: 8px;
    /* margin-bottom: 10px; */
    width: 70%;
  }
  
  .add-btn {
    background-color: #000000;
    color: white;
    padding: 10px 8px 10px 10px;

    border: none;
    border-radius: 0px;
    cursor: pointer;
    width: auto; /* Adjust the width to your desired value */
}

  
  .add-btn:hover {
    background-color: #45a049;
  }
  
  .caption-input {
    margin-bottom: 15px;
  }
  
  .caption-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* .model-details {
    margin-bottom: 15px;
  } */
  
  .input-field {
    width: 100%;
    padding: 07px;
    border: 1px solid #ccc;
    border-radius: 0px;
  }
  
  .note {
  font-size: 14px;
  color: #727171;
  margin-top: 5px;
  padding-left: 5px;
}
.banner-subheading {
    padding-top: 20px;
  }
  
  .start-btn {
    background-color: #070707;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .start-btn:hover {
    background-color: #089529d2;
  }
  
  .progress-tracker {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    width: 100%;
    height: 20px;
  }


  @media (max-width: 600px) {
  .create-agent-banner {
    padding: 0px;
  }
  .lora-training {
    max-width: auto !important;
    margin: auto;
    padding: 20px;
    padding-top: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    /* font-family: Arial, sans-serif; */
  }
  .banner-subheading {
    padding-top: 10px;
  }

  .create-agent-banner h3 {
    font-size: 1.25rem;
  }
 
  .create-agent-banner p {
    font-size: 1rem;
  }
}

.create-agent-banner {
  position: relative;  
  /* background-color: white; */
  color: #ffffff;            /* White text */
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  /* margin-bottom: 20px; */
  /* border-radius: 0px; */
  height: auto;
  /* font-family: sans-serif; */
}

.create-agent-banner h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.8rem;
  color: rgb(0, 0, 0);
  font-family: sans-serif;
}

.create-agent-banner p {
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.2rem;
  color: rgb(47, 47, 47);
  font-family: sans-serif;
}
/* Close Button Styles */
.close-banner-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  font-size: 24px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.close-banner-btn:hover {
  color: #cccccc;
}

.create-agent-banner .btn-primary {
  background-color: #196fcb;
  border-color: #007bff;
  color: #28a745;
}

/* Adjust button to look good on dark background */
.create-agent-banner .btn-primary {
  background-color: #28a745;
  border-color: #28a745;
}

.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Adjust button to look good on dark background */
.create-agent-banner .btn-primary {
  background-color: #28a745;
  border-color: #28a745;
}

.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
  
  </style>

<style scoped>
.floating-qr {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 300px;
  transition: all 0.3s ease;
}

.qr-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border: 1px solid #e1e4e8;
}

.qr-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: #f8f9fa;
  border-bottom: 1px solid #e1e4e8;
}

.qr-header h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.1rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #6c757d;
  cursor: pointer;
  padding: 0 0.5rem;
  line-height: 1;
}

.close-btn:hover {
  color: #343a40;
}

.qr-content {
  padding: 1.5rem;
}

.qr-card p {
  color: #6c757d;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

.qr-placeholder {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  border: 1px solid #e1e4e8;
}

.qr-image {
  width: 200px;
  height: 200px;
}

.qr-instructions ol {
  padding-left: 1.25rem;
  margin: 0;
  color: #495057;
}

.qr-instructions li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.qr-minimized {
  padding: 1rem 1.5rem;
  background: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: center;
  color: #2c3e50;
}

.qr-minimized:hover {
  background: #e9ecef;
}

.qr-minimized span {
  font-size: 0.95rem;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .floating-qr {
    right: 10px;
    width: 280px;
  }
  
  .qr-image {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 1200px) {
  .floating-qr {
    position: fixed;
    right: 10px;
    bottom: 20px;
    top: auto;
    transform: none;
    width: 260px;
  }
}

/* Hide on very small screens */
@media (max-width: 768px) {
  .floating-qr {
    display: none;
  }
}
</style>