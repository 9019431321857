// services/analytics.js

/**
 * Unified Analytics Service
 * Handles all tracking implementations (Google Analytics, Facebook Pixel, etc.)
 */
export class AnalyticsService {
  constructor() {
    this.initialized = false;
    this.googleTagId = process.env.VUE_APP_GOOGLE_TAG_ID || 'AW-16865687913';
    this.fbPixelId = process.env.VUE_APP_FACEBOOK_PIXEL_ID;
    
    // List of standard Facebook events
    this.standardFbEvents = [
      'AddPaymentInfo', 'AddToCart', 'AddToWishlist', 'CompleteRegistration',
      'Contact', 'CustomizeProduct', 'Donate', 'FindLocation', 'InitiateCheckout',
      'Lead', 'PageView', 'Purchase', 'Schedule', 'Search', 'StartTrial', 
      'SubmitApplication', 'Subscribe', 'ViewContent'
    ];
  }

  /**
   * Initialize all tracking scripts
   * @param {Object} options Configuration options
   */
  init(options = {}) {
    if (this.initialized) return;
    
    // Only initialize in production unless specified
    if (process.env.NODE_ENV !== 'production' && !options.enableInDev) {
      console.log('Analytics Service: Development mode - tracking disabled');
      this.initialized = true;
      return;
    }

    this.initGoogleTag();
    this.initFacebookPixel();
    this.initialized = true;

    // Log init in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Analytics Service: Initialized successfully');
    }
  }

  /**
   * Initialize Google Tag
   */
  initGoogleTag() {
    // Skip if already loaded
    if (window.gtag) return;
    
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
  
    // Load Google Tag script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleTagId}`;
    document.head.appendChild(script);
  
    // Basic configuration
    gtag('js', new Date());
    gtag('config', this.googleTagId);
  }

  /**
   * Initialize Facebook Pixel
   */
  initFacebookPixel() {
    // Skip if no Pixel ID or already initialized
    if (!this.fbPixelId || window.fbq) return;
    
    // Standard Facebook Pixel initialization code
    !function(f,b,e,v,n,t,s) {
      if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s);
    }(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
      
    fbq('init', this.fbPixelId);
    fbq('track', 'PageView');
  }

  /**
   * Check if Google Tag is loaded
   */
  isGtagLoaded() {
    return typeof window.gtag === 'function';
  }

  /**
   * Check if Facebook Pixel is loaded
   */
  isPixelLoaded() {
    return typeof window.fbq === 'function';
  }

  /**
   * Track an event to Google Tag
   * @param {string} eventName Event name
   * @param {Object} params Event parameters
   * @param {string} sendTo Optional conversion label
   */
  trackGoogleEvent(eventName, params = {}, sendTo = null) {
    try {
      if (!this.isGtagLoaded()) {
        console.warn('Google Tag not loaded');
        return;
      }

      const eventParams = {
        ...params,
        ...(sendTo && { 'send_to': sendTo })
      };

      window.gtag('event', eventName, eventParams);

      if (process.env.NODE_ENV === 'development') {
        console.log('Google Analytics Event:', {
          event: eventName,
          parameters: eventParams
        });
      }
    } catch (error) {
      console.error('Google Tag tracking error:', error);
    }
  }

  /**
   * Track an event to Facebook Pixel
   * @param {string} eventName Event name
   * @param {Object} params Event parameters
   */
  trackFacebookEvent(eventName, params = {}) {
    try {
      if (!this.isPixelLoaded()) {
        console.warn('Facebook Pixel not loaded');
        return;
      }
      
      // Use trackCustom for non-standard events
      if (this.standardFbEvents.includes(eventName)) {
        window.fbq('track', eventName, params);
      } else {
        window.fbq('trackCustom', eventName, params);
      }
      
      if (process.env.NODE_ENV === 'development') {
        console.log(`Facebook Pixel ${this.standardFbEvents.includes(eventName) ? 'Standard' : 'Custom'} Event:`, {
          event: eventName,
          parameters: params
        });
      }
    } catch (error) {
      console.error('Facebook Pixel tracking error:', error);
    }
  }

  /**
   * Track event to all configured platforms
   * @param {string} eventName Event name
   * @param {Object} params Event parameters
   */
  trackEvent(eventName, params = {}) {
    this.trackGoogleEvent(eventName, params);
    this.trackFacebookEvent(eventName, params);
  }

  /**
   * Track Google Ads conversion with callback
   * @param {Function} callback Callback function
   */
  trackGoogleAdsConversion(callback) {
    if (!this.isGtagLoaded()) {
      if (callback) callback();
      return;
    }
  
    window.gtag('event', 'conversion', {
      'send_to': `${this.googleTagId}/fARBCOGg354aEOnyl-o-`,
      'event_callback': callback
    });
  }

  // ----- STANDARD TRACKING METHODS -----

  /**
   * Track page view
   * @param {Object} routeData Route data from Vue Router
   */
  trackPageView(routeData) {
    const params = {
      page_title: routeData.meta?.pageTitle || document.title,
      page_path: routeData.path
    };

    this.trackGoogleEvent('page_view', params);
    this.trackFacebookEvent('PageView');
  }

  /**
   * Track detailed page navigation (custom event)
   * @param {Object} pageData Page navigation data
   */
  trackPageNavigation(pageData) {
    this.trackGoogleEvent('page_navigate', pageData);
    this.trackFacebookEvent('PageNavigate', pageData);
  }

  /**
   * Track custom event (for section views etc)
   * @param {string} eventName Custom event name
   * @param {Object} params Event parameters
   */
  trackCustomEvent(eventName, params = {}) {
    this.trackGoogleEvent(eventName, params);
    this.trackFacebookEvent(eventName, params);
  }

  /**
   * Track content view (standard FB event)
   * @param {Object} params Content parameters
   */
  trackViewContent(params = {}) {
    this.trackGoogleEvent('view_content', params);
    this.trackFacebookEvent('ViewContent', params);
  }

  /**
   * Track start of signup process
   * @param {string} method Signup method
   */
  trackSignUpStart(method) {
    const params = {
      method: method,
      step: 'initiate'
    };

    this.trackGoogleEvent('begin_sign_up', params, this.googleTagId);
    this.trackFacebookEvent('InitiateSignUp', { method: method });
  }

  /**
   * Track completion of signup
   * @param {Object} userData User data
   */
  trackSignUpComplete(userData = {}) {
    const params = {
      user_type: userData.account || 'free',
      signup_method: userData.loginMethod || 'email',
      value: 1.0,
      currency: 'USD'
    };

    // Track standard conversion events
    this.trackGoogleEvent('sign_up_complete', params);
    this.trackFacebookEvent('CompleteRegistration', {
      ...params,
      content_name: 'Account Registration',
      status: 'success'
    });

    // Track Google Ads conversion specifically
    this.trackGoogleAdsConversion();
  }

  /**
   * Track authentication error
   * @param {string} errorMessage Error message
   * @param {string} attemptedMethod Attempted auth method
   * @param {string} correctMethod Correct auth method (if applicable)
   */
  trackAuthError(errorMessage, attemptedMethod, correctMethod = null) {
    const params = {
      error_message: errorMessage,
      attempted_method: attemptedMethod
    };
    
    if (correctMethod) {
      params.correct_method = correctMethod;
    }
    
    this.trackGoogleEvent('authentication_error', params);
    this.trackFacebookEvent('AuthenticationError', params);
  }
  
  // ----- LEGACY SUPPORT METHODS -----
  
  /**
   * @deprecated Use trackSignUpStart
   */
  trackInitiateAuthentication(method) {
    this.trackSignUpStart(method);
  }
}

// Create and export a singleton instance
export const analyticsService = new AnalyticsService();

// For Vue plugin usage
export const analyticsPlugin = {
  install(Vue, options = {}) {
    // Initialize analytics
    analyticsService.init(options);
    
    // Add to Vue instance
    Vue.prototype.$analytics = analyticsService;
    
    // Auto-track page views if using Vue Router
    if (options.router) {
      options.router.afterEach((to) => {
        analyticsService.trackPageView(to);
      });
    }
  }
};

export default analyticsService;