// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7lEluR7GAjmOAOzLUOBejT5eiSE5CDGo",
  authDomain: "auth.briks.ai",
  databaseURL: "https://coursepal-12f95.firebaseio.com",
  projectId: "coursepal-12f95",
  storageBucket: "coursepal-12f95.appspot.com",
  messagingSenderId: "466443698194",
  appId: "1:466443698194:web:f1500da9010425ecd918cb",
  measurementId: "G-CQZ572G7TL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { analytics, auth };