// src/store/modules/subscription.js
import BillingService from '@/services/billingService'

const state = {
    subscription: null,
    loading: false,
    error: null
  }

const mutations = {
    SET_SUBSCRIPTION(state, subscription) {
        // Ensure usage and limits are nested within subscription
        state.subscription = {
          ...subscription,
          usage: subscription.usage || {},
          limits: subscription.limits || {}
        }
        
        // Update localStorage to maintain consistency
        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        userData.subscription = state.subscription
        localStorage.setItem('userData', JSON.stringify(userData))
      },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  }
}

const actions = {
    async fetchSubscriptionStatus({ commit }, userId) {
      commit('SET_LOADING', true)
      try {
        const response = await BillingService.getSubscriptionStatus(userId)
        if (response.success) {
          // Ensure we store both subscription status and limits
          commit('SET_SUBSCRIPTION', {
            ...response.status,
            usage: response.status.usage || {},
            limits: response.status.limits || {}
          })
        }
        return response
      } catch (error) {
        commit('SET_ERROR', error.message)
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async upgradePlan({ commit }, { userId, newPlanId }) {
        commit('SET_LOADING', true)
        try {
          const response = await BillingService.upgradePlan(userId, newPlanId)
          if (response.success && !response.requiresPayment) {
            commit('SET_SUBSCRIPTION', response.subscription)
          }
          return response
        } catch (error) {
          commit('SET_ERROR', error.message)
          throw error
        } finally {
          commit('SET_LOADING', false)
        }
      },

  async cancelSubscription({ commit }, { userId, reason }) {
    commit('SET_LOADING', true)
    try {
      const response = await BillingService.cancelSubscription(userId, reason)
      if (response.success) {
        commit('SET_SUBSCRIPTION', response.subscription)
      }
      return response
    } catch (error) {
      commit('SET_ERROR', error.message)
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

const getters = {
  isSubscriptionActive: state => state.subscription?.status === 'active',
  currentPlan: state => state.subscription?.planId || null,
  usagePercentage: state => (type) => {
    const usage = state.subscription?.usage?.[type] || 0
    const limit = state.subscription?.limits?.[type] || 1
    return Math.min(Math.round((usage / limit) * 100), 100)
  },
  imageCreationsUsage: state => ({
    used: state.subscription?.usage?.imageCreations || 0,
    limit: state.subscription?.limits?.imageCreations || 0
  }),
  
  imageProcessingUsage: state => ({
    used: state.subscription?.usage?.imageProcessing || 0,
    limit: state.subscription?.limits?.imageProcessing || 0
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}