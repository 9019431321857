// src/services/billingService.js
import axios from 'axios'


class BillingService {
    constructor() {
        this.baseURL = process.env.VUE_APP_BILLING_URL || 'https://api.briks.ai/billing'
      }

  // Get subscription status
  async getSubscriptionStatus(userId) {
    try {
      const response = await axios.get(`${this.baseURL}/subscription/status/${userId}`)
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  // Initialize subscription
  async initializeSubscription(userId, email, planId) {
    try {
      const response = await axios.post(`${this.baseURL}/subscription/initialize`, {
        userId,
        email,
        planId
      })
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  }
  

  // Upgrade subscription
  async upgradePlan(userId, newPlanId) {
    try {
        // Simply forward the upgrade request to backend
        const response = await axios.post(`${this.baseURL}/subscription/upgrade`, {
            userId,
            newPlanId
        });
        
        return response.data;
    } catch (error) {
        throw this.handleError(error);
    }
}

  // Cancel subscription
  async cancelSubscription(userId, reason = '') {
    try {
        const response = await axios.post(`${this.baseURL}/subscription/cancel`, {
            userId,
            reason,
            immediate: true // Always process cancellation immediately for upgrades
        });
        return response.data;
    } catch (error) {
        throw this.handleError(error);
    }
}

  // Verify Stripe session
  async verifyStripeSession(sessionId) {
    try {
      const response = await axios.get(`${this.baseURL}/subscription/verify-session/${sessionId}`)
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  // Get usage details
  async getUsageDetails(userId) {
    try {
      const response = await axios.get(`${this.baseURL}/usage/check`, {
        params: { userId }
      })
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  // Track usage
  async trackUsage(userId, operationType, quantity = 1) {
    try {
      const response = await axios.post(`${this.baseURL}/usage/track`, {
        userId,
        operationType,
        quantity
      })
      return response.data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  // Handle errors
  handleError(error) {
    console.error('Billing Service Error:', error)
    return {
      success: false,
      error: error.response?.data?.error || 'An error occurred in the billing service',
      status: error.response?.status
    }
  }
}

export default new BillingService()