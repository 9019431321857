<template>
  <transition name="fade-up-in" appear>
    <div
      v-if="shown"
      class="pwa-prompt"
      :class="[{ shown }]"
    >
      <span class="pwa-prompt-text" v-if="!isAndroid && !isIOS">Install BRIKS App: </span>
      <span class="pwa-prompt-text" v-if="isAndroid">Get BRIKS App: </span>
      <span class="pwa-prompt-text" v-if="isIOS">Install BRIKS App: </span>

      <div class="pwa-buttons">
        <template v-if="isIOS">
          <div class="ios-install-instructions">
            <span>Tap <feather-icon icon="Share2Icon" size="16" /> then 'Add to Home Screen'</span>
          </div>
        </template>
        <button v-else class="install-button" @click="handleInstall">
          {{ isAndroid ? 'Install Now' : 'Install Now' }}
        </button>

        <button class="close-button" @click="dismissPrompt">
          <span aria-hidden="true">×</span>
          <span class="sr">Dismiss without installing</span>
        </button>
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  data: () => ({
    installEvent: undefined,
    shown: false,
    isAndroid: false,
    isIOS: false,
    promptTimer: null,
    promptDelay: 5000, // 5 seconds delay
  }),
  
  beforeMount() {
    this.detectPlatform()
    
    if (!this.isAndroid && !this.isIOS) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.installEvent = e
        this.startPromptTimer()
      })
    }
  },

  mounted() {
    this.startPromptTimer()
  },
  beforeDestroy() {
    // Clear timer if component is destroyed
    if (this.promptTimer) {
      clearTimeout(this.promptTimer)
    }
  },

  methods: {

    startPromptTimer() {
      // Clear any existing timer
      if (this.promptTimer) {
        clearTimeout(this.promptTimer)
      }

      // Set new timer
      this.promptTimer = setTimeout(() => {
        this.checkIfAppInstalled()
      }, this.promptDelay)
    },

    detectPlatform() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      this.isAndroid = /android/.test(userAgent)
      this.isIOS = /iphone|ipad|ipod/.test(userAgent)
    },

    async checkIfAppInstalled() {
      // Check if it's a PWA
      if (window.matchMedia('(display-mode: standalone)').matches || 
          window.navigator.standalone === true) {
        this.shown = false
        return
      }

      // For Android - Check if app is installed using Digital Asset Links
      if (this.isAndroid) {
        try {
          // Try app.briks.ai first, then briks.ai as fallback
          const intents = [
            `intent://app.briks.ai/#Intent;scheme=https;package=com.briks.briksapp;end`,
            `intent://briks.ai/#Intent;scheme=https;package=com.briks.briksapp;end`
          ]
          
          const timeout = setTimeout(() => {
            this.shown = true // Show install prompt if app not installed
          }, 500)

          // Try first intent
          window.location.href = intents[0]
          
          window.onblur = () => {
            clearTimeout(timeout)
            this.shown = false // App is installed
          }

          // If first intent fails, try second after a short delay
          setTimeout(() => {
            if (this.shown) {
              window.location.href = intents[1]
            }
          }, 100)

        } catch (e) {
          this.shown = true // Show install prompt if check fails
        }
      } 
      // For iOS - Show PWA install instructions
    else if (this.isIOS) {
      // Only show on Safari as PWAs can only be installed from Safari on iOS
      const isSafari = /safari/i.test(navigator.userAgent) && 
                      !/chrome|android/i.test(navigator.userAgent)
      this.shown = isSafari
    }
    // For Web
    else {
      this.shown = !!this.installEvent
    }
  },

    handleInstall() {
      if (this.isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.briks.briksapp'
      } else {
        this.installPWA()
      }
    },

    installPWA() {
      if (!this.installEvent) return

      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt()
        if (choice.outcome === 'accepted') {
          this.shown = false
        }
      })
    },

    dismissPrompt() {
      this.shown = false
    }
  }
}
</script>


<style scoped lang="scss">
// If repurposing this component, you'll want to update the CSS variables.
.pwa-prompt {
  position: fixed;
  font-size: 1.25rem;
  z-index: 20;
  line-height: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
//   height: 4rem;
    height: 4.85rem;
//   background: var(--dark);
  background: #111;
  color: var(--background);
  transform: translateY(0);
  margin: 0;

  .install-button {
    font-size: inherit;
    margin: 0 0 0 0.75rem;
    padding: 0.35em 0.5em;
    // background-color: var(--bright);
    background-color:#111;
    // border: 0;
    border:1px solid #fff;
    color: #fff;
    border-radius: 4px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
  }
  .close-button {
    position: absolute;
    // right: 0;
    right: 30px;
    top: -.25rem;
    font-size: 3rem;
    background: transparent;
    color: rgba(255, 255, 255, 0.8) !important;
    border: 0;
    padding: 0 0.75rem;
    height: 100%;
    line-height: 1;
  }
  // For the accessible text. Most projects have their own .sr-only class or similar in the base styles.
  .sr {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -100vw;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
}

.fade-up-in-enter-active, .fade-up-in-leave-active {
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), 
              transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(0);
}

.fade-up-in-enter, .fade-up-in-leave-to {
  opacity: 0;
  transform: translateY(4rem);
}

.pwa-prompt-text{
    color: rgba(255, 255, 255, 0.8) !important;
}
.fade-up-in-enter-active, .fade-up-in-leave-active {
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(0);
}
.fade-up-in-enter, .fade-up-in-leave-to {
  opacity: 0;
  transform: translateY(4rem);
}

@media only screen and (max-width: 600px) {
.pwa-prompt {
    font-size:1rem !important;
}
.pwa-prompt .close-button {
    right: 0 !important;
}
}

@media (max-width: 360px) {
  .pwa-prompt {
    font-size:14px !important;
}
.pwa-prompt .install-button{
    margin: 0 0 0 0.5rem;
}
}
</style>

<style scoped lang="scss">
// Add these new styles to your existing styles

.ios-install-instructions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 0.9rem;
  margin-left: 0.75rem;
  
  svg {
    vertical-align: middle;
  }
}

// Mobile optimization for iOS instructions
@media only screen and (max-width: 600px) {
  .ios-install-instructions {
    font-size: 0.85rem;
    margin-left: 0.5rem;
  }
}

@media (max-width: 360px) {
  .ios-install-instructions {
    font-size: 0.8rem;
  }
}
</style>