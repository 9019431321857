<template>
<div id="app" class="h-100">
<!-- <div id="app" class="h-100" :class="[skinClasses]"></div> -->
    <component :is="layout">
        <router-view />
    </component>

<!-- <div class="">
    <b-link id="tooltip-chatgpt-info" class="chatgpt-toggle d-flex align-items-center justify-content-center hidden-sm"  @click='initializeChat()'>
        <feather-icon icon="MessageCircleIcon" size="32"/>
    </b-link>
    <b-tooltip :show='true' placement="left" target="tooltip-chatgpt-info" variant="primary">Ask Creative Assistant</b-tooltip>
</div> -->

    <!-- <support-desk /> -->
    <chat-gpt />
    <PWAPrompt />

</div>
</template>

<script>
import {
    VBToggle,
    BTooltip
} from 'bootstrap-vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
// import SupportDesk from '@/views/Website/support/SupportDesk.vue'
import ChatGpt from '@/views/Website/support/ChatGpt.vue'
import PWAPrompt from '@/views/Website/PWAPrompt.vue'

// This will be populated in `beforeCreate` hook
import {
    $themeColors,
    $themeBreakpoints,
    $themeConfig
} from '@themeConfig'
import {
    provideToast
} from 'vue-toastification/composition'
import {
    watch
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {
    useWindowSize,
    useCssVar
} from '@vueuse/core'

import store from '@/store'

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

import { mapState, mapActions } from 'vuex';

export default {

    name: 'App',
  
    metaInfo() {

    const defaultImage = {
      url: 'https://media.briks.ai/images/image-2984bfd7-2f01-4efe-a160-155595f25458.png',
      width: 1200,
      height: 630,
      alt: 'BRIKS.AI - AI-Powered Product Photography'
    };

    return {
      title: 'BRIKS.AI',
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
        
        // Default SEO tags
        { name: 'description', content: 'Create stunning visuals with BRIKS AI' },
        { name: 'keywords', content: 'AI photo editing, professional photos, content creation' },
        
        // Default OG tags
        { property: 'og:site_name', content: 'BRIKS.AI' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://briks.ai' },
        { property: 'og:title', content: 'BRIKS.AI' },
        { property: 'og:description', content: 'Create stunning visuals with BRIKS AI' },
         { property: 'og:image', content: defaultImage.url },
        { property: 'og:image:width', content: defaultImage.width },
        { property: 'og:image:height', content: defaultImage.height },
        { property: 'og:image:alt', content: defaultImage.alt },
        
        // Default Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@BRIKSAI' },
        { name: 'twitter:title', content: 'BRIKS.AI' },
        { name: 'twitter:description', content: 'Create stunning visuals with BRIKS AI' },
        { name: 'twitter:image', content: defaultImage.url },
        { name: 'twitter:image:alt', content: defaultImage.alt }
      ],
      link: [
        { rel: 'canonical', href: 'https://briks.ai' }
      ]
    }
  },


    components: {

        LayoutFull,
 
        // SupportDesk,
        ChatGpt,
        PWAPrompt,
        ScrollToTop,
        BTooltip,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            userId: '',
            show: true
        }
    },

    computed: {
        layout() {
            if (this.$route.meta.layout === 'full') return 'layout-full'
            return `layout-${this.contentLayoutType}`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
        companyName() {
            return this.$store.state.app.companyName
        },
        isLogged() {
      return this.$store.getters['app/isLogged'];  // Check if the user is logged in
    },
    userData() {
      return this.$store.getters['app/userData'];  // Get the logged-in user data
    },  
    },
    created() {
        this.$store.dispatch('app/syncUserFromStorage');  // Sync user data from localStorage to Vuex
        // this.gettenantDomain();
    },
    beforeCreate() {
        // Set colors in theme
        const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
        }

        // Set Theme Breakpoints
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
        }

        // Set RTL
        const {
            isRTL
        } = $themeConfig.layout
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    },

    methods: {
    //     initializeChat() {
    //     this.$store.commit("app/SET_INITIALIZE_CHAT", true);
    // },

        // async gettenantDomain() {

        //     const url = window.location.href
        //     const hostname = new URL(url);

        //     var domain_name = hostname.hostname.replace('.cloudweb.app', '')

        //     var myHeaders = new Headers();

        //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //     var urlencoded = new URLSearchParams();
        //     urlencoded.append("domain_name", domain_name);

        //     var requestOptions = {
        //         method: "POST",
        //         headers: myHeaders,
        //         body: urlencoded,
        //         redirect: "follow",   
        //     };

        //     const rawResponse = await fetch(
        //         process.env.VUE_APP_BASE_URL + "tenantInfoByDomain",
        //         requestOptions
        //     );

        //     const response = await rawResponse.json();
        //     if (response.status == true) {

        //         this.$store.commit("app/SET_TENANT_DOMAIN_DATA", JSON.stringify(response.data[0]));
        //         // this.$store.commit("app/SET_FETCHING_FIELDS", response.data[0].fetching_fields);
        //         //   this.$store.commit("app/SET_IS_VERIFIED", response.data[0].is_user_features_required);

        //         // this.$store.commit("app/SET_INDEX_NAME", response.data[0].azure_index_name);
        //         localStorage.setItem('pwa_logo', response.data[0].branding_icon);

        //         localStorage.setItem('pwa_url', response.data[0].manifest_url);
        //         document.title = response.data[0].organisation_display_name
        //         this.$store.commit("app/SET_APP_PROFILE", response.data[0].branding_icon);
        //         // this.$store.commit("app/SET_IMAGE_URL", response.data[0].company_logo);

        //         this.$store.commit("app/SET_COMPANY_NAME", response.data[0].organisation_display_name);
        //         // this.$store.commit("auth/SET_PRIVACY_URL", response.data[0].privacy_url);
        //         // this.$store.commit("app/SET_FILTER_FIELDS", response.data[0].filter_fields);
        //         // this.$store.dispatch('app/getBannerDetails')
        //         // this.getProducts();
        //         // this.getUserDetailsByID();

        //     }

        // },


        async getUserDetailsByID() {
           
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

            var myHeaders = new Headers();

            const userData = localStorage.getItem("userData")

            if (userData == null || undefined) {
                this.userId = null
            } else {
                this.userId = JSON.parse(localStorage.getItem("userdetails")).userId;
            }

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            // myHeaders.append('x-api-key', api_key);

            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("userId", this.userId);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "profile",

                requestOptions
            );

            const response = await rawResponse.json();
            if (response.data.length !== 0) {
                this.$store.commit("app/SET_PROFILE_URL", response.data[0].profile_url);
                localStorage.setItem('user_profile', response.data[0].profile_url);

                this.$store.commit("app/SET_FULL_NAME", response.data[0].full_name);
                this.$store.commit("app/SET_USER_ROLE", response.data[0].user_role);
                this.$store.commit("app/SET_USER_EMAIL", response.data[0].email);
                    this.$store.commit("app/SET_USER_COUNTRY", response.data[0].country);
                this.$store.commit("app/SET_USER_ID", response.data[0].userId);

                // this.$store.commit("app/SET_USER_AGE", response.data[0].age);
                // this.$store.commit("app/SET_USER_GENDER", response.data[0].gender_name);
                // this.$store.commit("app/SET_USER_HEIGHT", response.data[0].height);
                // this.$store.commit("app/SET_USER_WEIGHT", response.data[0].weight);
                // this.$store.commit("app/SET_USER_FEATURE", response.data[0].is_user_features_required);
            }

        },
    },

    setup() {
        const {
            skin,
            skinClasses
        } = useAppConfig()
        const {
            enableScrollToTop
        } = $themeConfig.layout

        // If skin is dark when initialized => Add class to body
        if (skin.value === 'dark') document.body.classList.add('dark-layout')

        // Provide toast for Composition API usage
        // This for those apps/components which uses composition API
        // Demos will still use Options API for ease
        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: 'Vue-Toastification__fade',
        })

        // Set Window Width in store
        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        const {
            width: windowWidth
        } = useWindowSize()
        watch(windowWidth, val => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val)
        })

        return {
            skinClasses,
            enableScrollToTop,
        }
    },
}
</script>
