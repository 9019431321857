import {
  $themeBreakpoints
} from '@themeConfig'
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    fetching_fields: '',
    shallShowOverlay: false,
    cartLength: 0,
    cartData: [],
    companyName: '',
    profileImage: '',
    fullName: '',
    userEmail: '',
    userID: '',
    userRole: '',
    likeDislikeID: 0,
    // chatId: [],
    prompt_template: "",
    isLogged: false,
    isEnquire: 0,
    isSideFilter:false,
    filterArrays:'',
    isVerified:false,
    searchEnquiry: '',
    productList: [],
    searchQuery: '',
    chatVisibility: false,
    productId: null,
    query_type: '',
    featuredProduct: false,
    banner: [],
    featured_product_list: [],
    featured_images: [],
    tenantProfile: '',
    imageUrl: '',
    fieldVectors: '',
    vectorQueries: [],
    collections: [],
    filter_fields: [],
    userCountry:'',
    filters: [],
    filter_query: [],
    index_name: '',
    search: '',
    user_featured: 0,
    feedImages: [],
    userData: null,
    initializeChat: false,
    isChatInitialized: false, 
    chatData: [],
    chatId: localStorage.getItem('chatId') || 0,
    agents: [],
    isLoading: false,
  },
  getters: {
    allAgents: (state) => state.agents,
    isLoading: (state) => state.isLoading,
    banner: state => state.banner,
    getFeedImages: (state) => state.feedImages,
    featured_images: state => state.featured_images,
    isLogged: (state) => !!state.isLogged,
    userData: (state) => state.userData,
    currentBreakPoint: state => {
      const {
        windowWidth
      } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {

    SET_AGENTS(state, agents) {
      state.agents = agents;
      localStorage.setItem('agents', JSON.stringify(agents)); 
    },
    UPDATE_WINDOW_WIDTH(state, value) {
      state.windowWidth = value
    },
    SET_IS_VERIFIED(state,value){
          state.isVerified=value
    },
    TOGGLE_OVERLAY(state, value) {
      state.shallShowOverlay = value !== undefined ? value : !state.shallShowOverlay
    },
    SET_TENANT_DOMAIN_DATA(state, value) {
      localStorage.setItem("domain_Data", value)
    },

    SET_COMPANY_NAME(state, value) {
      state.companyName = value
    },
    SET_INITIALIZE_CHAT(state, value) {
      state.chatVisibility = value
    },
    SET_CHAT_INITIALIZED(state, value) {
      state.isChatInitialized = value;
  },

    SET_APP_PROFILE(state, value) {
      state.tenantProfile = value
    },
     SET_CHAT_VISIBILITY(state, value) {
    state.chatVisibility = value;
  },

    SET_STATUS(state, value) {
      if (value == "") {
        state.searchQuery = "";
      } else {
        if (state.searchQuery === '') {

          state.searchQuery = value;
        } else {

          state.searchQuery += ` ${value}`;
        }
      }

    },
    SET_USER_ID(state, value) {
      state.userID = value
    },
    SET_USER_EMAIL(state, value) {
      state.userEmail = value
    },
    SET_REMOVE_ITEMS() {
      // Remove userData from localStorage
      localStorage.removeItem('userName')
      localStorage.removeItem('userData');
      localStorage.removeItem('user_profile')
      localStorage.removeItem('tenant_Data')
      // localStorage.removeItem('domain_Data')

      localStorage.removeItem('name')
      localStorage.removeItem('profile_url')
    },

    SET_COMMA(state, val) {
      state.search += val
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },


    // UPDATE_SEARCH(state, value) {
    //   state.search = state.search.replace(value, '');

    // },

    SET_FEED_IMAGES(state, images) {
      state.feedImages = images;
    },
    SET_IMAGE_URL(state, val) {
      state.imageUrl = val
    },
   
    // SET_USER(state, value) {
    //   state.isLogged = value
    // },
   
    SET_PROFILE_URL(state, value) {
      state.profileImage = value
    },
    SET_FULL_NAME(state, value) {
      state.fullName = value
    },
    SET_USER_COUNTRY(state, value) {
      state.userCountry = value
    },
    SET_USER_ROLE(state, value) {
      state.userRole = value
    },
    SET_LIKE_DISLIKE_ID(state, value) {
      state.likeDislikeID = value

    },
    SET_CHAT_ID(state, value) {
      state.chatId = value;
      if (value) {
          localStorage.setItem('chatId', value);  // Persist chatId in localStorage
      } else {
          localStorage.removeItem('chatId');
      }
  },
  SET_CHAT_DATA(state, data) {
    state.chatData = data;
    localStorage.setItem('chatData', JSON.stringify(data));
},
CLEAR_CHAT(state) {
  state.chatId = null;
  state.chatData = [];
  localStorage.removeItem('chatId');
  localStorage.removeItem('chatData');
},
    SET_BANNER(state, val) {
      state.banner = val
    },
    SET_FEATURED_IMAGES(state, val) {
      state.featured_images = val;
    },
    SET_USER(state, isLogged) {
      state.isLogged = isLogged;
    },
    SET_USER_DETAILS(state, userData) {
      state.userData = userData;
    },
    CLEAR_USER(state) {
      state.isLogged = false;
      state.userData = null;
    },   
  },

  actions: {
    // async getBannerDetails({ commit }) {
    //   try {
    //     const tenantData = JSON.parse(localStorage.getItem("domain_Data"));
    //     if (!tenantData) {
    //       throw new Error("No tenant data found in localStorage");
    //     }
    
    //     const { tenant_id, api_key } = tenantData;
    //     const myHeaders = new Headers();
    //     myHeaders.append('x-api-key', api_key);
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    //     const urlencoded = new URLSearchParams();
    //     urlencoded.append("tenant_id", tenant_id);
    //     urlencoded.append("user_type", "public_user");
    
    //     const requestOptions = {
    //       method: "POST",
    //       headers: myHeaders,
    //       body: urlencoded,
    //       redirect: "follow"
    //     };
    
    //     // const rawResponse = await fetch(`${process.env.VUE_APP_BASE_URL}banners`, requestOptions);
    
    //     if (!rawResponse.ok) {
    //       const errorText = await rawResponse.text();
    //       throw new Error(`HTTP error! Status: ${rawResponse.status}, Response: ${errorText}`);
    //     }
    
    //     const response = await rawResponse.json();
        
    //     // Check if the response contains the expected data
    //     if (!response || !response.data) {
    //       throw new Error(`Unexpected response format: ${JSON.stringify(response)}`);
    //     }
    
    //     commit('SET_BANNER', response.data);
    //   } catch (error) {
    //     console.error('Error fetching banner:', error.message);
    //     console.error('Error details:', error);
    //   }
    // },

    async fetchUserData({ commit, state }) {
      try {
        const userId = state.userData?.userId;
        if (!userId) return;
  
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}getUser/${userId}`);
        const data = await response.json();
        
        if (data.success) {
          // Update both localStorage and Vuex state
          localStorage.setItem('userData', JSON.stringify(data.data));
          commit('SET_USER_DETAILS', data.data);
          return data.data;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
    },
  
    // Optional: Add specific action for updating store details
    updateStoreDetails({ commit, state }, storeDetails) {
      const updatedUserData = {
        ...state.userData,
        storeDetails: storeDetails
      };
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      commit('SET_USER_DETAILS', updatedUserData);
    },

    async fetchAgents({ commit, state }) {
      if (state.agents.length === 0) {
        commit('SET_LOADING', true);
        try {
          const response = await axios.get(`${process.env.VUE_APP_BASE_URL}agents`);
          commit('SET_AGENTS', response.data);
        } catch (error) {
          console.error('Error fetching agents:', error);
        } finally {
          commit('SET_LOADING', false);
          
        }
      }
    },

    async fetchFeedImages({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}getFeed`);
        const data = await response.json();
        if (Array.isArray(data.images)) {
          localStorage.setItem('feed_images', JSON.stringify(data.images));
          commit('SET_FEED_IMAGES', data.images);
        } else {
          console.error('Expected an array of feed images, but got:', data.images);
        }
      } catch (error) {
        console.error('Error fetching feed images:', error);
      }
    },
    loadFeedImagesFromStorage({ commit }) {
      const images = JSON.parse(localStorage.getItem('feed_images') || '[]');
      commit('SET_FEED_IMAGES', images);
    },
    
    async fetchFeaturedItems({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}getFeaturedItems`);
        const data = await response.json();
        if (Array.isArray(data.images)) {
          localStorage.setItem('featured_images', JSON.stringify(data.images));
          commit('SET_FEATURED_IMAGES', data.images);
        } else {
          console.error('Expected an array of featured images, but got:', data.images);
        }
      } catch (error) {
        console.error('Error fetching featured items:', error);
      }
    },
    login({ commit }, userData) {
      // Set userData in localStorage and Vuex state
      localStorage.setItem('userData', JSON.stringify(userData));
      commit('SET_USER', true);
      commit('SET_USER_DETAILS', userData);
    },
    logout({ commit }) {
      // Remove userData from localStorage and Vuex state
      localStorage.removeItem('userData');
      commit('CLEAR_USER');
    },
    syncUserFromStorage({ commit }) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        commit('SET_USER', true);
        commit('SET_USER_DETAILS', userData);
      } else {
        commit('CLEAR_USER');
      }
    },
    loadFeaturedImagesFromStorage({ commit }) {
      const images = JSON.parse(localStorage.getItem('featured_images') || '[]');
      commit('SET_FEATURED_IMAGES', images);

    },

  

  },
}